.App {
    background-color: #fff;
    font-size: 15px;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #777777;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    /*max-width: 80%;*/
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}

.App-content {
    min-height: 100vh;
    display: inline;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App-footer {
    /*max-width: 80%;*/
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.App-link {
    color: #09d3ac;
}

#inner {
    overflow: hidden;
}

.child {
    float: left;
    padding: 10px;
    width: calc(50% - 20px);
    height: 700px;
}

#inner1 {
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
}

.child-dropdown {
    float: left;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 5px;
    font-weight: bold;
}

.child-text {
    float: left;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #777777;
    font-size: 15px;
    margin-top: 12px;
}

.radio-group {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #777777;
    font-size: 15px;
    margin-left: 10px;
}

.header-recognition {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #777777;
    font-size: 15px;
    margin-top: 15px;
    font-weight: bolder;
}

#dropdown-item {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #777777;
    font-size: 15px;
}

#dropdown-header {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #777777;
    font-size: 15px;
}

.inlineList {
    columns: 4;
    -webkit-columns: 4;
    -moz-columns: 4;
}
